import React, { useState } from 'react';
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export const pageQuery = graphql`
        query {
            allStrapiContact {
                edges {
                    node {
                        seo {
                            metaTitle
                            metaDescription
                            shareImage {
                              url
                            }
                        },
                        contactWay {
                            title 
                            info
                        },
                        address {
                            title,
                            info,
                            picture {
                                localFile {
                                    childImageSharp {
                                      gatsbyImageData(width: 880, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                                    }
                                  }
                            }
                        }
                    }
                  }
            }
        }`
    

export default function Contact({ data,location }) {
    
    const [headerColor,setHeaderColor] = useState("bg-white text-black")
    const node = data.allStrapiContact.edges[0].node 

    const [submitLoading,setSubmitLoading] = useState(false)
    const submitFun = (e)=>{
        e.preventDefault()
        const name = e.target.querySelector("[name='name']").value || ''
        const surname = e.target.querySelector("[name='surname']").value || ''
        const job = e.target.querySelector("[name='job']").value || ''
        const email = e.target.querySelector("[name='email']").value
        const description = e.target.querySelector("[name='content']").value
        const phone = e.target.querySelector("[name='tel']").value || ''
        const weixin = e.target.querySelector("[name='weixin']").value || ''
        const isEmail = e.target.querySelector("[name='way']").checked

        setSubmitLoading(true)
        fetch('https://admin.cnsotto.com/questions-opinions-forms',{
            method:'POST',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
            body: JSON.stringify({
                name,surname,job, email, description, phone, weixin, isEmail
            })
        })
        .then(res =>res.json())
        .then((data) => {
            setSubmitLoading(false)
            if(data.id){
                Toastify({
                    text: `你的问题或意见已经提交成功!`,
                    position:"center"
                }).showToast();
            }
        })
    }
    
    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            
            <div className="container mx-auto pt-[193px] pb-[140px] sm:pb-20 sm:pt-28">
                <div className="flex justify-between items-end pb-[100px] sm:flex-col sm:items-start sm:pb-14">
                    <h1 className="text-[40px] leading-snug sm:mb-8">取得联系</h1>
                    <ul className="flex items-center flex-wrap text-gray sm:w-full sm:justify-center">
                        <li className="mr-[38px] sm:mr-5"><a className="text-black" href="/contact">联系方式</a></li>
                        <li className="mr-[38px] sm:mr-5"><a className="hover:text-black" href="/job">工作机会</a></li>
                        <li className="mr-[38px] sm:mr-5"><a className="hover:text-black" href="/supplier">供应商体系</a></li>
                        <li><a href="/sale-service" className="hover:text-black">售后服务</a></li>
                    </ul>
                </div>
                
                <div className="flex justify-between pb-[216px] xl:flex-col sm:pb-20">
                    <h2 className="text-3xl leading-snug items-start xl:mb-8">
                        联系方式
                    </h2>
                    <div className="grid grid-cols-3 gap-x-10 gap-y-[60px] w-[940px] xl:w-full xl:grid-cols-2 sm:grid-cols-1 sm:gap-y-[30px]">
                    {
                        node.contactWay.map((n,i)=>{
                            return (
                                <div key={i}>
                                    <div>{n.title}</div>
                                    <div dangerouslySetInnerHTML={{__html: n.info}}/>
                                </div>
                            )
                        })
                    }
                        
                        
                       
                    </div>
                </div>
                <div className="flex justify-between pb-[134px] xl:flex-col sm:pb-20">
                    <h2 className="text-3xl leading-snug items-start xl:mb-8">
                    提交问题或意见
                    </h2>
                    <form onSubmit={submitFun} className="grid grid-cols-3 gap-x-[50px] gap-y-10 w-[940px] xl:w-full sm:grid-cols-2 sm:gap-x-8">
                        <div>
                            <input type="text" name="name" placeholder="名字" required/>
                        </div>
                        <div>
                            <input type="text" name="surname" placeholder="姓氏" required/>
                        </div>
                        <div>
                            <input type="text" name="job" placeholder="职位" />
                        </div>
                        <div>
                            <input type="email" name="email" placeholder="电子邮箱" required/>
                        </div>
                        <div>
                            <input type="tel" name="tel" placeholder="电话" required/>
                        </div>
                        <div>
                            <input type="text" name="weixin" placeholder="微信号" />
                        </div>
                        <div className="col-span-3 sm:col-span-2">
                            <textarea placeholder="主题" name="content" cols="30" rows="10"></textarea>
                        </div>
                        <label className="col-span-3 sm:col-span-2 -mt-6">
                            <input type="checkbox" name="way" className="mr-1"/>
                            请通过电子邮件或短信联系我
                        </label>
                        <div className="col-span-3 sm:col-span-2">
                            <input type="submit" disabled={submitLoading} className="disabled:cursor-not-allowed disabled:opacity-50 w-[140px] sm:w-full h-10 flex justify-center items-center rounded-full border-[2px] border-black text-black bg-transparent hover:bg-black hover:text-white" value="提交"/>
                        </div>
                    </form>
                </div>
                <div id="industryAdress">
                    <h2 className="text-3xl leading-snug pb-10">工厂和各地经销处</h2>
                    <div className="grid grid-cols-3 gap-x-[60px] gap-y-[55px] xl:grid-cols-2 md:grid-cols-1 sm:gap-y-10">
                    {
                        node.address.map((n,i)=>{
                            return (
                                <div key={i}>
                                    <figure className="overflow-hidden"><GatsbyImage className="w-full bg-light transform transition-all duration-1000 ease-linear hover:scale-110" image={getImage(n.picture.localFile)} alt="article picture"/></figure>
                                    <p className="font-semibold py-2.5">{n.title}</p>
                                    <p>{n.info}</p>
                                </div>)
                        })
                    }
                    </div>
                </div>
            </div>
        </Layout>
    )
}

